import React from "react"
import {graphql} from "gatsby"

import '../../css/custom.css'
import Layout from "../../components/layout_en"
import {Container, Row, Col, Button} from "react-bootstrap"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline";
import Seo from "../../components/seo"
import {GatsbyImage} from "gatsby-plugin-image";
import logoTrust from "../../images/logo-bar-customers.png";
import Slider from "react-slick";
//import { GatsbyImage } from "gatsby-plugin-image";

/*import Image from "../components/image"
import SEO from "../components/seo"*/

const IndexPage = ({data}) => (

    <Layout>
        <Seo
            title="Cash Management Software That Will Simplify Your Life."
            lang="en"
        />
        <Container>

            {data.allStrapiHomeSection1.edges.map(section1 => (
                <Row className="home-box-header justify-content-center" key={section1.node.id}>
                    <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1">

                        <h1 className="title">Manage your cash flow fast and easly</h1>
                        <p className="section">Make better decisions and look to the future with confidence. Trezorino allows you to monitor and forecast your cash flow in a simple and flexible way.</p>


                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                            </Col>
                        </Row>

                    </Col>

                    <Col className="home-box-image order-1 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                        {/*  {section1.node.video &&

                            <video className="box-shadow" key={section1.node.video.id} src={section1.node.video.url} width="100%" height="100%" loop muted playsInline autoPlay></video>
                        }*/}

                        {section1.node.Image480?.map( image=> (
                            <GatsbyImage fluid={image.localFile.childImageSharp.fluid} className="" key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        ))}

                    </Col>

                </Row>
            ))}
        </Container>

        <Container>
            <Col className="pt-5">
                <Row className=" center justify-content-center">
                    <h2 className="title-trust">Join the customers who trust us</h2>
                </Row>
                <Row>
                    <Slider lazyLoad={"progressive"} arrows={false} touchMove={false} dots={false} pauseOnHover={false} infinite={true} slidesToShow={1} slidesToScroll={1} autoplay={true} speed={40000} autoplaySpeed={0} cssEase={"linear"}>
                        <div>

                            <img
                                alt=""
                                variant="top"
                                width="100%"
                                src={logoTrust}
                            />

                        </div>
                        <div>
                            <img
                                alt=""
                                variant="top"
                                width="100%"
                                src={logoTrust}
                            />

                        </div>
                    </Slider>
                </Row>
            </Col>
            <Col>

            </Col>

        </Container>

        <Container>

            {data.allStrapiHomeSection2.edges.map(section2 => (

                <Row className="home-box justify-content-center" key={section2.node.id} >

                    <Col className='my-auto home-box-image order-1 order-sm-1 order-lg-1' xs={12} sm={12} md={6} lg={6} xl={6} >
                        {section2.node.MainImage?.map( image=> (
                            <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        ))}
                    </Col>

                    <Col className=" my-auto home-box-text order-2 order-sm-2 order-lg-2" >
                        <h2 className="title">Secure the future of your business...</h2>

                        <p className="section">We help company managers better analyze and anticipate their cash flow with customized support and an innovative solution that automatically centralizes all the financial data needed to make reliable forecasts.</p>

                        <p className="section">With Trezorino, keep a clear vision of your financial flows and find the solutions that will guarantee the development of your company.</p>

                        {/*   <Row className="pt-4">
                            <Col className="left justify-content-center pt-1" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo">Demandez une démo</Button>
                            </Col>
                        </Row>*/}



                    </Col>
                </Row>

            ))}

        </Container>

        <Container className="container-collab">
            {data.allStrapiHomeSection4.edges.map(section4 => (
                <Row key={section4.node.id} className="home-box-1 justify-content-center">

                    <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1" >
                        <h2 className="title">Efficient cash flow monitoring</h2>

                        <p className="section-h2">Centralizing your financial transactions provides you with consistent and reliable data directly from your bank accounts and business applications.</p>
                        <p className="section-h2">Updates to your cash flow statements are automatic, so you don't have to worry about whether your spreadsheet is displaying the right numbers or whether your formulas are working correctly.</p>
                        <p className="section-h2">This allows you to focus more on the essentials: analysis and decision making.</p>


                        <Row className="pt-1 center">
                            <Col className="left justify-content-center mt-2" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="link-kom" style={{width:"100%"}} className="ensavoirplus" href="/en/features/cash-flow-monitoring/">Learn more <IoChevronForwardOutline></IoChevronForwardOutline></Button>
                            </Col>
                        </Row>

                    </Col>



                    <Col className='my-auto home-box-image order-1 order-sm-1 order-lg-1' xs={12} sm={12} md={6} lg={6} xl={6} >
                        {section4.node.Image992?.map( image=> (
                        <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        ))}
                    </Col>

                </Row>
            ))}
        </Container>

        <Container className="">
            {data.allStrapiHomeSection3.edges.map(section3 => (
                <Row key={section3.node.id} className="home-box-1 justify-content-center">
                    <Col  className="my-auto home-box-image order-1 order-sm-1 order-lg-1">
                        {section3.node.Image480?.map( image=> (
                            <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        ))}
                    </Col>

                    <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h2 className="title">A safer cash flow forecast</h2>

                        <p className="section-h2">With Trezorino, you will be able to anticipate your financing needs, plan your investments at the right time and visualize the evolution of your cash flow.</p>
                        <p className="section-h2">Cash flow forecasts and scenarios help you plan for the future and respond effectively to unexpected events.</p>




                        <Row className="pt-4">
                            {/*<Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="demo" style={{width:"100%"}} className="" href="/en/request-demo">Demandez une démo</Button>
                            </Col>*/}
                            <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="link-kom" style={{width:"100%"}} className="ensavoirplus" href="/en/features/cash-flow-forcast/">Learn more <IoChevronForwardOutline></IoChevronForwardOutline></Button>
                            </Col>
                        </Row>

                    </Col>


                </Row>
            ))}
        </Container>

        <Container className="container-blue">
            {data.allStrapiHomeSection4.edges.map(section4 => (
                <Row key={section4.node.id} className="home-box-1 justify-content-center">

                    <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1" >
                        <h2 className="title">Control your costs and improve your margins</h2>

                        <ul className="check">
                            <li><p className="section-h2">Plan your expenses to avoid cash flow problems that can slow down your company development.</p></li>
                            <li><p className="section-h2">Immediately identify where and how you need to act by easily analyzing your budget overruns.</p></li>
                            <li><p className="section-h2">Reduce your costs by playing the competition and keep a clear vision of the evolution of your expenses over several months.</p></li>
                        </ul>


                        <Row className="pt-4">
                            <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="demo" style={{width:"100%"}} className="" href="/en/request-demo/">Request Demo</Button>
                            </Col>
                        </Row>

                    </Col>

                    <Col  className="my-auto home-box-image order-1 order-sm-2 order-lg-2" xs={12} sm={12} lg={6} xl={6} md={6}>
                        {section4.node.Image480?.map( image=> (
                            <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        ))}
                    </Col>

                </Row>
            ))}
        </Container>

        <Container className="">
            {data.allStrapiHomeSection3.edges.map(section3 => (
                <Row key={section3.node.id} className="home-box-1 justify-content-center">
                    <Col  className="my-auto home-box-image order-1 order-sm-1 order-lg-1">
                        {section3.node.Image768?.map( image=> (
                        <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        ))}
                    </Col>

                    <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h2 className="title">Plan your growth strategy</h2>

                        <p className="section-h2">Identify the skills you need to meet a future increase in your activity. Build your recruitment plan and anticipate its cost.</p>
                        <p className="section-h2">Do you need new equipment for your business? Make your purchases and financing requests at the right time.</p>

                        <Row className="pt-4">
                            <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="demo" style={{width:"100%"}} className="" href="/en/request-demo/">Request demo</Button>
                            </Col>
                        </Row>

                    </Col>


                </Row>
            ))}
        </Container>

        <Container className="container-collab">
            {data.allStrapiHomeSection6.edges.map(section6 => (
                <Row key={section6.node.id} className="home-box-1 justify-content-center">

                    <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1" >
                        <h2 className="title">Collaborative cash management</h2>

                        <ul className="check">
                            <li><p className="section-h2">Enhance the cash culture by engaging the collective effort.</p></li>
                            <li><p className="section-h2">Build your forecasts with the key players in your company and act effectively on your cash flow.</p></li>
                            <li><p className="section-h2">Share your ideas, documents, and keep your collaborators informed in real time.</p></li>
                        </ul>


                        <Row className="pt-4">
                            <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="link-kom" style={{width:"100%"}} className="ensavoirplus" href="/en/features/collaborative-cash-management/">Learn more <IoChevronForwardOutline></IoChevronForwardOutline></Button>
                            </Col>
                        </Row>

                    </Col>

                    <Col  className="my-auto home-box-image order-1 order-sm-2 order-lg-2" xs={12} sm={12} lg={6} xl={6} md={6}>
                        {data.allStrapiHomeSection4.edges.map(section4Vid => (
                       /* {section6.node.video?.map( vid=> (*/
                           /* <video key={section4Vid.node.Image768.id} src={section4Vid.node.Image768.url} width="100%" height="100%" loop muted playsInline autoPlay></video>*/
                            <video src="https://trezorino-uat-strapi.s3.us-east-2.amazonaws.com/Tresorerie_collaborative_blue_eng_insta_ef62c24eaa.mp4" width="100%" height="100%" loop muted playsInline autoPlay></video>

                            ))}


                    </Col>

                </Row>
            ))}
        </Container>


        <Container className="">
            {data.allStrapiHomeSection3.edges.map(section8 => (
                <Row key={section8.node.id} className="home-box-1 justify-content-center">
                    <Col  className="my-auto home-box-image order-1 order-sm-1 order-lg-1">
                        {section8.node.Image992?.map( image=> (
                        <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        ))}
                    </Col>

                    <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h2 className="title">Cash management for all organizations</h2>

                        <p className="section-h2">Trezorino offers the possibility to manage cash flows at all levels of a company, whether it is an entity, an agency, a department or a project.</p>
                        <p className="section-h2">It allows everyone to benefit from centralized financial data in real time, to make forecasts, to develop action plans and to simulate the impacts of specific strategies.</p>


                        <Row className="pt-4">
                            <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="demo" style={{width:"100%"}} className="" href="/en/request-demo/">Request demo</Button>
                            </Col>
                        </Row>

                    </Col>


                </Row>
            ))}
        </Container>


        <Container className="container-support">


            {data.allStrapiHomeSection1.edges.map(teams => (
                <Row key={teams.node.id} className="home-box-1 justify-content-center">

                    <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1" >
                        <h2 className="title">You will never be alone.</h2>

                        <ul className="check">
                            <li><p className="section">Your first steps are assured by a complete training.</p></li>
                            <li><p className="section">You can contact our teams at any time by phone, chat or email.</p></li>
                            <li><p className="section">A dedicated cash flow expert who accompanies you throughout your journey.</p></li>
                        </ul>

                    </Col>

                    <Col   className="my-auto home-box-image order-1 order-sm-2 order-lg-2" xs={12} sm={12} lg={6} xl={6} md={6}>

                        {teams.node.video &&
                            <GatsbyImage key={teams.node.video.id} image={teams.node.video.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        }


                        {/*  <picture >

                        <source srcSet={teams} media="(max-width: 480px)"></source>


                        <source  srcSet={teams} media="(max-width: 768px)"></source>


                        <source  srcSet={teams} media="(max-width: 992px)"></source>

                        <img  className="image1" src={teams} alt="" style={{border: "0,1px solid #E9E9E9"}}/>

                    </picture>*/}

                    </Col>

                </Row>
            ))}
        </Container>

        <Container className="align-items-center justify-content-center pb-8 pt-8">
            <Row className="home-box-1 justify-content-center">
                <Col classname=" my-auto home-box-text order-1 order-sm-1 order-lg-1 pb-5">
                    <h2 className="center title">Want to see Trezorino in action?</h2>
                    <Row   className="center justify-content-center">
                        <Button variant="demo"  className="home-last-button" href="/en/request-demo/">Request demo</Button>
                    </Row>

                </Col>
            </Row>
        </Container>

    </Layout>

)

export default IndexPage

export const data = graphql`  
query home_en {
  allStrapiHomeSection1 {
    edges {
      node {
        id
        Title
        Description
        ShowButton1
        TextButton1
        ShowButton2
        TextButton2
        video {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, width:700)    
            }
          }
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, width:700)  
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, width:700)  
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection2 {
    edges {
      node {
        id
        Title
        Description1
        Description2
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiTrustLogos {
    edges {
      node {
        id
        Logo {
          id
          url
           localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiTitleTrustLogo {
    edges {
      node {
        id
        Title
      }
    }
  }
  allStrapiHomeSection3 {
    edges {
      node {
        id
        Title
        SubTitle
        Description
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection3Bullets {
    edges {
      node {
        id
        Order
        Text
      }
    }
  }
  allStrapiHomeSection4 {
    edges {
      node {
        id
        Description
        Title
        Image992 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          url
          id
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection5 {
    edges {
      node {
        id
        video {
          url
          id
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection6 {
    edges {
      node {
        id
        video {
          url
          id
        }
      }
    }
  }
  allStrapiHomeSection7 {
    edges {
      node {
        id
        video {
          url
          id
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection8 {
    edges {
      node {
        id
        video {
          url
          id
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
}
`
